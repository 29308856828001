<template>
  <div>
  <b-container fluid class="mb-3" v-if="!loading">
    <b-row>
      <b-col>
        <h4 class="card-title"><strong>{{item.name}}</strong></h4>
        <div v-if="item.taxonomy.length > 0">
         <in-line :rows="item.taxonomy"></in-line>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="my-4 mr-4 p-3 shadow">
        <div class="my-2">
          <strong>Data elements</strong>
        </div>
        <div v-if="item.dataelements.length > 0">
          <div v-for="element in item.dataelements" :key="'dataelemenmt-' + item.id + '-' + element.id">
            <router-link :to="{ name: 'DataElement', params: { id: element.id }}">{{ element.name }}</router-link>
          </div>
        </div>
      </b-col>
      <b-col class="my-4 p-3 shadow">
        <div class="my-2">
          <strong>Reporting frameworks</strong>
        </div>
        <div v-if="item.reportingframeworks.length > 0">
          <div v-for="framework in item.reportingframeworks" :key="'framework-'+ item.id + '-' + framework.id">
            <router-link :to="{ name: 'MetricsReportingframework', params: { id: framework.id }}">{{ framework.name }}</router-link>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="relatedMetrics.length > 0" class="my-4 shadow p-3 bg-white">
      <b-col class="my-4">
        <strong>Related metrics with common data elements</strong>
        <div class="my-2" style="max-height: 40vh; overflow-y: auto;  overflow-x: auto;">
          <div v-for="metric in relatedMetrics" :key="'relate-dmetric-' + metric.id">
            <router-link :to="{ name: 'Metric', params: { id: metric.id }}">{{ metric.name }}</router-link>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row class="shadow p-3 bg-white">
      <b-col class="my-4">
        <div class="my-2">
          <strong>Metrics and data elements graph</strong>
        </div>
        <div id="graph" style="height: 40vh;"></div>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import _ from 'lodash'
import delay from '@/libs/delay'
import InLine from '@/components/taxonomy/InLine.vue'
import * as echarts from 'echarts'
import theme from '@/libs/colorTheme'
echarts.registerTheme('harveyBalls', theme)

export default {
  name: 'Metric',
  components: {
    InLine
  },
  computed: {
    dataelements: function () {
      return this.$store.state.dataelements
    },
    metrics: function () {
      return this.$store.state.metrics
    }
  },
  created: function () {
    this.load()
  },
  data () {
    const data = {
      item: {},
      loading: true,
      relatedMetrics: []
    }
    return data
  },
  methods: {
    load: async function () {
      this.loading = true
      this.relatedMetrics = []
      this.item = this.metrics.find(item => item.id === Number.parseInt(this.$route.params.id))
      let links = []
      this.item.dataelements.forEach(element => {
        links.push({ source: 'metric-' + this.item.id, target: 'dataelement-' + element.id, uuid: 'metric-' + this.item.id + 'dataelement-' + element.id })
      })
      let nodes = []
      nodes.push({ id: 'metric-' + this.item.id, name: this.item.name, category: 0, symbolSize: 20 })
      if (this.item.dataelements.length > 0) {
        const intersection = _.intersectionBy(this.dataelements, this.item.dataelements, 'id')
        intersection.forEach(row => {
          this.relatedMetrics = this.relatedMetrics.concat(row.metrics)
          row.metrics.forEach(metric => {
            links.push({ source: 'metric-' + metric.id, target: 'dataelement-' + row.id, uuid: 'metric-' + metric.id + 'dataelement-' + row.id })
          })
        })
        this.relatedMetrics = _.uniqBy(this.relatedMetrics, 'id')
        this.relatedMetrics = _.orderBy(this.relatedMetrics, ['name'], ['asc'])
        this.relatedMetrics.forEach(metric => {
          nodes.push({ id: 'metric-' + metric.id, name: metric.name, category: 2 })
        })
      }
      this.item.dataelements.forEach(element => {
        nodes.push({ id: 'dataelement-' + element.id, name: element.name, category: 1 })
      })
      nodes = _.uniqBy(nodes, 'id')
      links = _.uniqBy(links, 'uuid')
      const categories = [
        {
          name: 'The metric'
        },
        {
          name: 'Data element'
        },
        {
          name: 'Related metric'
        }
      ]
      const options = {
        tooltip: {},
        legend: [
          {
            data: categories.map(function (a) {
              return a.name
            })
          }
        ],
        series: [
          {
            name: 'Metrics and data elements',
            type: 'graph',
            layout: 'force',
            data: nodes,
            links: links,
            categories: categories,
            roam: true,
            label: {
              position: 'right'
            },
            force: {
              repulsion: 100
            }
          }
        ]
      }
      await delay(100)
      this.loading = false
      await delay(30)
      echarts.init(document.getElementById('graph'), 'harveyBalls').setOption(options)
    }
  },
  watch: {
    '$route': function () {
      this.load()
    }
  }
}
</script>
